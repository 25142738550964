<template>
  <div class="flex items-center justify-center h-screen bg-gray-300">
		<!-- Container -->
		<div class="container mx-auto">
			<div class="flex justify-center px-6 my-12">
				<!-- Row -->
				<div class="w-full xl:w-3/5 lg:w-11/12 flex shadow-xl border-1 border-gray-400">
					<!-- Col -->
					<div
            id="animated"
						class="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
					>
          </div>
					<!-- Col -->
					<div class="w-full lg:w-1/2  p-5  lg:rounded-l-none rounded-r-lg" style="background-color: rgba(255, 255, 255,1)">
						<div class="px-8 text-center">
              <h4 class="font-semibold mt-5 mb-5 pb-1 text-3xl">Reset Password</h4>
						</div>
            <form>
              <!-- BEGIN: Password --->
              <div>
                <v-text-field v-model="password1"  
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" 
                  :rules="[passwordRules]"
                  :type="show ? 'text' : 'password'"
                  class="input-group--focused" dense 
                  outlined placeholder="Enter password" 
                  @click:append="show = !show" 
                />
              </div>
              <!-- END: Password --->
              <!-- BEGIN: Password 2 --->
              <div>
                <v-text-field v-model="password2"  
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                  :rules="[passwordRules]"
                  :type="show1 ? 'text' : 'password'"
                  class="input-group--focused" dense 
                  outlined placeholder="Enter password" 
                  @click:append="show1 = !show1" 
                />
              </div>
              <!-- END: Password 2--->
              <div class="text-center pt-1 mb-6 pb-1">
                <v-btn block color="primary" depressed @click="resetPassword()">
                  RESET
                </v-btn>
              </div>
              <div class="flex items-center justify-between pb-6">
                  <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToLogin()">Go to login</span>
                  <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer" @click="goToHome()">Go Back Home</span>
              </div>
            </form>
          </div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    password1: null,
    password2: null,
    loading: false,
    step:1
  }),

  methods: {
    resetPassword() {
      this.$store.dispatch("resetPassword", {
        token: this.$route.params.token,
        newPassword1: this.password1,
        newPassword2: this.password2
      });

      this.password1 = "",
      this.password2 = ""
      
    },

    goToLogin(){
      this.$router.push({ name: "Login" })
    },
    goToHome(){
      this.$router.push({ name: "Landing"})
    }
  },
};
</script>

<style scoped>
#animated {
  background: url("../../assets/images/Kilimanjaro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>